import { apiUrl } from "../../../setting";

export const getCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/customerList`;
};

export const getBank = () => {
    return `${apiUrl}/routes/customer/getBank`;
};

export const getStockUrl = () => {
    return `${apiUrl}/routes/product/getStock`;
};

export const addCreditNoteUrl = () => {
    return `${apiUrl}/routes/creditNote/addCreditNote`;
};

export const editSaleUrl = () => {
    return `${apiUrl}/routes/sale/editSale`;
};

export const getCreditNoteUrl = () => {
    return `${apiUrl}/routes/creditNote/getAllCreditNote`;
};

export const invoiceUpdateUrl = () => {
    return `${apiUrl}/routes/sale/updateInvoice`;
};

export const getGroupCustomerDataUrl = () => {
    return `${apiUrl}/routes/customer/getCustomerDataByGroupId`;
};

export const getbatchRecordByProductIdUrl = () => {
    return `${apiUrl}/routes/sale/getBatchRecordByProductId`;
}
export const PrintSaleUrl = (id) => {
    return apiUrl + "/routes/sale/printSaleRecord/" + id;
};

export const PrintCreditNoteUrl = (id) => {
    return apiUrl + "/routes/creditNote/printCreditNoteRecord/" + id;
};

export const getBatchForCreditNote = () => {
    return `${apiUrl}/routes/sale/getBatchForCreditNote`;
}
export const getCreditNoteCsvUrl = () => {
    return `${apiUrl}/routes/creditNote/getAllCreditNoteCsv`;
};

export const getUserDataUrl = () => {
    return `${apiUrl}/routes/user/getSingleUser`;
};